import React from "react";
import Servers from "../Servers/Servers";
import SynStates from "../SynStates/SynStates";

const Dashboard = () => (
    <div className="row">
        <SynStates/>
    </div>
)

export default Dashboard;