import React from "react";
import SERVERS from '../../data/servers.json'
import axios from "axios";

export default class WebsterCheckInOut extends React.Component {
    state = {
        servers: SERVERS
    }

    componentDidMount() {
        const servers = this.state.servers;
        for (let i in servers) {
            this.getCheckInOutRowsCount(servers[i]);
        }
    }

    render() {
        return (
            <div className='row'>
                <div className="col-6">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>IP</th>
                            <th>Domain</th>
                            <th>Rows</th>
                        </tr>

                        </thead>
                        <tbody>
                        {this.state.servers.map((server) => (
                            <tr key={server.id}>
                                <td>{server.id}</td>
                                <td>{server.name}</td>
                                <td>{server.ip}</td>
                                <td>{server.domain}</td>
                                <td>{server.rows}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

            </div>
        )
    }

    getCheckInOutRowsCount(server) {
        let servers = this.state.servers;


        const url = server.domain + "/monitoring/webster/check-in-out";
        axios.get(url)
            .then(res => {
                for (let i in servers) {
                    if (servers[i].id == server.id) {
                        servers[i].rows = res.data.data;
                    }
                }

                this.setState({servers});
            }).catch((error) => {
            for (let i in servers) {
                if (servers[i].id == server.id) {
                    servers[i].rows = -1;
                }
            }

            this.setState({servers});
        });
    }
}