import React from "react";
import './Header.css';
import Navbar from "../navbar/Navbar";

const Header = () => (
    <header className="header">
        <h1>Monitoring</h1>
        <Navbar/>
    </header>
);

export default Header;