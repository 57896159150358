import './App.css';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Layout from "./Layout/Layout";
import Dashboard from "./Dashboard/Dashboard";
import Servers from "./Servers/Servers";
import WebsterCheckInOut from "./webster/check-in-out/WebsterCheckInOut"

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path="/" exact element={<Dashboard/>}>
                        </Route>
                        <Route path="/servers" exact element={<Servers/>}>
                        </Route>
                        <Route path="/webster/check-in-out" exact element={<WebsterCheckInOut/>}>
                        </Route>
                    </Routes>
                </Layout>
            </BrowserRouter>
        </div>
    );
}

export default App;
