import React from "react";
import axios from 'axios';

export default class SyncStateTable extends React.Component {
    state = {
        rows: [{
            name: 'test'
        }]
    }

    componentDidMount() {
        const server = this.props.server;
        const url = server.domain + "/monitoring/sync/get"
        axios.get(url)
            .then(res => {
                const rows = res.data.data;
                this.setState({rows: rows});
            })
    }

    render() {


        return (
            <table className='table table-striped'>
                <thead>
                <th>id</th>
                <th>entity</th>
                <th>started_at</th>
                <th>finished_at</th>
                <th>result</th>
                <th>last_synced_id</th>
                <th>total_records</th>
                </thead>
                <tbody>
                {this.state.rows.map((row) => (
                    <tr>
                        <td>{row.id}</td>
                        <td>{row.entity}</td>
                        <td>{row.started_at}</td>
                        <td>{row.finished_at}</td>
                        <td>{row.result}</td>
                        <td>{row.last_synced_id}</td>
                        <td>{row.total_records}</td>
                    </tr>))
                }


                </tbody>
            </table>
        );
    }
}

