import React from "react";
import './Navbar.css';
import {Link} from "react-router-dom";

const Navbar = () => (
    <nav>
        <ul>
            <li>
                <Link to="/">
                    Dashboard
                </Link>
            </li>
            <li>
                <Link to="/servers">
                    Servers
                </Link>
            </li>
            <li>
                <Link to="/webster/check-in-out">
                    Webster
                </Link>
            </li>

        </ul>
    </nav>
);

export default Navbar;