import React from "react";
import SERVERS from '../data/servers.json'
import SyncStateTable from "../SyncStateTable/SyncStateTable";


const SynStates = () => (
    <div className='row'>
        {SERVERS.map((server) => (
            <div className='col-md-6'>
                <div key="{server.id}" className=' card p-1 mb-3 ml-3'>
                    <h2>{server.name}</h2>
                    <SyncStateTable server={server}/>
                </div>
            </div>
        ))}
    </div>
)

export default SynStates;