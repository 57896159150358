import React from "react";
import SERVERS from '../data/servers.json'
import axios from "axios";

export default class Servers extends React.Component {
    state = {
        servers: SERVERS
    }


    componentDidMount() {
        const servers = this.state.servers;
        for (let i in servers) {
            this.getStatus(servers[i]);
        }
    }

    render() {
        return (
            <div className='row'>
                <div className="col-6">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>IP</th>
                            <th>UserName</th>
                            <th>Status</th>
                            <th>Version</th>
                            <th>Free Space</th>
                            <th>Action</th>
                        </tr>

                        </thead>
                        <tbody>
                        {this.state.servers.map((server) => (
                            <tr key={server.id}>
                                <td>{server.id}</td>
                                <td>{server.name}</td>
                                <td>{server.ip}</td>
                                <td>{server.domain}</td>
                                <td className={server.active ? 'text-success' : 'text-danger'}>{server.active ? 'Active' : 'Inactive'}</td>
                                <td>{server.version}</td>
                                <td>{server.free_space}</td>
                                <td>
                                    <div className="btn-group" role="group">
                                        <button className='btn btn-sm btn-primary'
                                                onClick={() => this.updateServer(server)}>
                                            Update
                                        </button>
                                        <button className='btn btn-sm  btn-success'
                                                onClick={() => this.getStatus(server)}>
                                            Status
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-6">
                    <div className="card bg-light mt-4">
                        Output: {this.state.output}
                    </div>
                </div>
            </div>
        )
    }

    getStatus(server) {

        let servers = this.state.servers;


        const url = server.domain + "/monitoring/status";
        axios.get(url)
            .then(res => {
                for (let i in servers) {
                    if (servers[i].id == server.id) {
                        servers[i].active = true;
                        servers[i].version = res.data.version;
                        servers[i].free_space = res.data.free_space;
                    }
                }

                this.setState({servers});
            }).catch((error) => {
            for (let i in servers) {
                if (servers[i].id == server.id) {
                    servers[i].active = false;
                }
            }

            this.setState({servers});
        });
    }

    updateServer(server) {
        let state = this.state;
        state.output = null;
        this.setState(state);
        const url = server.domain + "/monitoring/server/update";
        axios.get(url)
            .then(res => {
                let state = this.state;
                state.output = res.data;
                this.setState(state);
            }).catch((error) => {
            let state = this.state;
            state.output = error.message;
            this.setState(state);
        });
    }
}

